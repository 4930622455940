import React from "react"

import Layout from "../components/layout"
import { MainHeader, WhyChooseUs } from "../components/sections"
import SEO from "../components/seo"
import { BlackSection, IntroSection } from "../components/structure"

const AboutUs = () => (
  <Layout>
    <SEO title="Who we are" />
    <MainHeader />
    <IntroSection
      title="About us"
      margin="1.5rem 0 0 0"
      content="ICThub is an IT and telecommunications industry leader. Based in Melbourne and Sydney, we are officially recognised as one of the top 10 Australian companies specialising in unified communications, internet, and voice mobility. We’ve held a prime position in the ICT industry for 15 years, amassing an infinite list of satisfied customers and invaluable experience along the way."
    />
    <IntroSection
      margin="0"
      content="The IT services we provide range from Cloud services and IT procurement to cyber security and digital workplaces. We offer consultations and guidance, setup and installation, and ongoing management of all our services. And when IT problems arise, our qualified and reliable team are only a phone call away."
    />
    <IntroSection
      margin="0"
      content="We work closely with each customer to deliver a tailored approach that suits the individual needs of the business. Our services improve the efficiency of an organisation, reduce costs, and increase customer satisfaction, all of which work together to drive booming business growth."
    />
    <IntroSection
      margin="0"
      content="In the era of the smartphone, having an advanced IT system for your business is essential for success. Call us today to find out how we can use innovative IT solutions to make your business more productive."
    />
    <BlackSection title="How we can help your business?" />
    <WhyChooseUs />
  </Layout>
)

export default AboutUs
